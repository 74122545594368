<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Category information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Category Name" v-if="category" v-model="category.name" autocomplete="off" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Fuel:</label>
          <div class="col-md-10">
            <select class="form-control"  v-if="category" v-model="category.type">
              <option value="1">General</option>
              <option value="101">Petrol</option>
              <option value="102">Diesel</option>
            </select>
          </div>

        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'VehicleCategoryForm',
  store,
  data () {
    return {
      category: JSON.parse('{"id":0,"name":"","type":0}')
    }
  },
  component: {

  },
  props: {
    mycategory: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"name":"","type":0}')
    }
  },
  beforeMount () {
    this.category = this.mycategory; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.category = JSON.parse('{"id":0,"name":"","type":0}')
  },
  mounted () {
    $('.form-control-select2').select2();
    $('#txtname').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('category_window_closed');
    },
    saveDocument () {
      const self = this;

      if (self.$data.category.name.toString().length < 3) {
        alert('Invalid Name');
        return
      }

      self.$data.category.type = parseInt(self.$data.category.type);

      const requestOptions = {
        method: (self.$data.category.id == 0 ? 'POST' : 'PUT'),
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.category)
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/vehiclecategory`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Success!', text: resp.msg, type: 'success' , onClose: () => { $('#txtname').focus()}, timer:1500 });
          self.category = JSON.parse('{"id":0,"name":"","type":0}');
          self.$emit('category_saved', resp.data);
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
